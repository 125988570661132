<template>
  <form @submit.prevent="submit" class="form">
    <section class="content">
      <slot />
      <div class="actions">
        <slot name="actions" />
      </div>

      <div class="alert alert--error" v-if="errorMsg">{{ this.errorMsg }}</div>
    </section>

    <transition name="fade">
      <Loading v-if="busy" class="overlay" />
    </transition>
  </form>
</template>

<script>
export default {
  props: {
    operation: {
      type: Function,
      required: true,
    },
    valid: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      errorMsg: null,
      busy: false,
    };
  },

  methods: {
    async submit() {
      if (this.valid && !this.busy) {
        this.errorMsg = null;
        this.busy = true;
        try {
          await this.operation();
        } catch (e) {
          this.errorMsg = e.message;
        }
        this.busy = false;
      }
    },
  },
  watch: {
    error: function () {
      this.errorMsg = this.error;
    },
  },
};
</script>

<style scoped></style>
